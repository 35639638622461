.modal-box-css{
  min-width: 51vw !important;
  height: 100% !important;
  }
  
.modal-mains-css{
    position: fixed;   
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    height:80% !important;
}

.modal-mains-img-css{
  position: fixed;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-container-css{
  height: auto;
  border-radius: 0 !important;
}

.modal-container-img-css{
  height: auto;
  border-radius: 0 !important;
  width: 280px;
  margin: auto;
}

.modal-open .modal-mains-css{
    top: 3rem;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    right: 0rem;
}


