n.primary-text-color {
	color: #162D6E;
}
.secondary-text-color {
	color:#46B147;
}
.gray-text-color {
	color: #707070;
}

.header-text {
  padding-top:25px;
  font-size:30px;
  color: #001E2E;
  margin-top: 10px;
  margin-left: 12px;
}
.sub-text{
  font-size: 16px;
  padding-top: 10px;
  line-height: 1.2;
  font-weight: 300;
  color:#001E2E;
}
.sub-text-padding{
  font-size: 16px;
  padding-top: 10px;
  line-height: 1.2;
  font-weight:300;
  color:#001E2E;
  }
.count-highlighted{
  font-size: 30px;
  color: #09819A;
  font-weight: 700;
  line-height: 0.6;
  font-weight:500;
}
.count-unhighlighted {
  padding-left:10px;
  font-family:"ROBOTO REGULAR";
  color: #09819A;
  font-size: 30px;
}
.btn-green-bg {
  background-color: #4B606C;
  font-size: 12px !important;
  border-radius: 3px !important;
  padding: 3px 6px;
  border-color: #4B606C;
  float:right;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #001E2E !important;
  border-color: #001E2E !important;
}
.btn-green-bg-bottom{
  margin-bottom: 1.4rem;
}
.btn-green-adjacent{
  background-color: #001E2E !important;
  font-size:12px !important;
  margin-left:20px;
  padding: 4px 4px;
  border-radius: 2px !important;
  border-color: #001E2E;
  float:right;
}

.btn-margin{
  margin-top: 4.1rem !important;
  margin-bottom: 1.4rem !important;
}

.survey-btn{
  margin-top: 2.25rem !important;
  margin-bottom: 1.4rem !important;
}

.btn-warning-bg {
  padding: 5px 6px;
  background-color:#4B606C !important;
  font-size:11px !important;
  border:none !important;
  color: #ffff !important;
  margin-left:6px;
  border-radius: 3px !important;
}
.btn-warning-bg:hover{
  background-color:#4B606C !important;
  color: #ffff !important;
  border:none !important;
  border-color:#4B606C !important;
}
.btn-warning-bg:active{
   outline: none !important;
   box-shadow: none;
}
.btn-warning-bg:focus{
   outline: none !important;
   box-shadow: none;
}
.custom-my-3{
  margin-top: 1.4rem !important;
  margin-bottom: 1rem !important;
}

.icon-border{
  background-color:#E2ECF1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.page-background {
	background-color: #EFEFEF;
}
.page-footer {
    background-color: white;
    color: #707070 !important;
    font-family: 'roboto', sans-serif !important;
    font-size: 12px !important;
    margin-bottom: -1px;
}
.page-footer-padding {
	  padding-right: 60px;
    padding-left: 60px;
    line-height: 0.8;
    margin-top: -10px;
    font-weight: 400;
    color:#001E2E;
}
.payroll-setting{
  padding-left: 5px;
  color: #0F2BB4;
}
.payroll-underline{
  color:#006CD1 !important;
}
.payroll-underline:hover{
  color:#162d6e !important;
}
.page-small-text{
	font-size:12px;
	font-family:'roboto',sans-serif !important;
	color:#707070;
}


@media screen and (max-width: 1280px){
  .count-highlighted {
      font-size: 50px;
  }
  .sub-text-padding {
    font-size: 16px;
    padding-right: 10px;
  }
  .btn-green-bg {
      font-size: 10px !important;
      padding: 6px;
  }
  .btn-warning-bg {
      font-size:10px !important;
      padding: 6px;
    }

  .count-unhighlighted {
      font-size: 25px;
  }
  .btn-green-adjacent {
      font-size: 10px !important;
      margin-top: 1px;
      padding:6px;
  }
  .header-text{
    font-size:21px;
  }
  .page-footer{
    font-size: 11px !important;
  }
  .page-footer-padding{
      line-height: 0.2;
      margin-top: -15px;
  }
}

.bulk-td{
  white-space: unset !important;
  word-break: break-word !important;
}
.bulk-emp-btn{
  font-size: 13px !important;
  padding: 7px !important;
}


.sub-text-db{
  font-size: 12px;
  color: #707070;
  padding-left: 5px;
  font-weight: 500;
  font-family: "Roboto";
  margin-bottom: 4px; 
}
.btn-db{
    border-radius: 2px !important;
    border: none !important;
    font-size: 13px !important;
    padding: 6px 92px 6px 92px !important;
}
.btn-text-highlighted{
  font-size: 56px;
  color: white;
  font-weight: 700;
  line-height: 0.7;
  font-family: roboto slab;
}


.line-modal{
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ccc;
    z-index: 1;
}

.wordwrapper-modal {
    text-align: center;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -12px;
    z-index: 2;
}

.word-modal {
    color: #ccc;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px;
    font: bold 12px arial,sans-serif;
    background: #fff;
}
.onboard-override .input-group .form-control{
  padding: 0.5rem 0.5rem !important;
}
.btn-elongated{
  padding-right: 72px !important;
  padding-left: 72px !important;
}

.btn-elongated-restart{
  padding-right: 64px !important;
  padding-left: 64px !important;
}
.card-disable-view{
  opacity:0.2;
}

.green-btn-dashboard{
  background: #09819A !important;
}
.modal-onboard .modal-content{
  width: 620px !important;
}
.dashboard-title-other{
  font-weight: 300;
}
/*.emp-onboard{
  width:460px;
  display: inline-block;
}
.emp-second-onboard{
  width:500px;
  display: inline-block;
  margin-left: 77px;
}*/

.cover-sidenav {
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
}
.uncover-sidenav{
  display: block;
}
.nav-wrapper-onboard{
  height: 70vh;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 99999;
}
.side-nav-wrapper-onboard{
  opacity: 0.5
}

.tracking-modal.modal-lg {
  max-width: 1070px !important;
  background-color : #efefef !important;
  border-radius: 0.3rem;
}

.tracking-modal .modal-content {
  background-color : #efefef !important;
}
.map-side-bar{
  max-height:450px;
  overflow-y:auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.tracking-modal-outline{
  outline: none !important;
  cursor: pointer;
}
.negative-map{
  margin-left: -5px;
}
.Select.is-disabled > .Select-control{
  cursor: not-allowed !important;
  background-color: #e0e0db !important;
  color: #4E4D4A;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.Select-menu{
  overflow-x: hidden !important;
} 
.emp-dashboard-modal{
  max-width: 670px;
}

.pop-up-modal .modal-header{
  border-bottom: none;
}
.pop-up-modal .modal-footer{
  border-top: none;
  justify-content: center !important;
} 
.job-block-elipsis{
  display: -webkit-box;
  max-width: 100%;
  max-height: 36px;
  margin: 0 auto;
  font-size: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.emp-dropdown-override .Select-menu{
  overflow-x: hidden !important;
}

.pop-up-modal .modal-header{
  border-bottom: none;
}
.pop-up-modal .modal-footer{
  border-top: none;
  justify-content: center !important;
}

.icon-dtimecard{
  outline: none !important;
  vertical-align: super !important;
}

.color-subtable-dt{
  padding-right: 7px;
  margin-left: 10px;
  padding-left: 4px;
  border-radius: 74%;
  padding-top: 0px;
}
.btn-export-emp{
  margin-top: -36px !important;
}
.vertical-align-text-top{
  vertical-align: top;
}

.icon-vertical-align{
  vertical-align: baseline;
}
.donotshow-message{
  font-style: italic;
  font-size: 12px;
  margin-bottom: -15px;
  margin-top: 22px;
}

.info-modal-dt{
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.text-info-managingdt{
  text-align: center;
  padding-top: 177px;
  font-size: 15px;
}
.popover.popover-top, .popover.bs-tether-element-attached-bottom {
    background-color: white !important;
    margin-top: -10px;
    border-radius: 0.4rem;
  &:after {
    border-top-color: darkgrey;
  }
}   

.popover.popover-top, .popover.bs-tether-element-attached-bottom{
  background-color: white !important;
  margin-top: -10px;
  border-radius: 0.4rem;
  &:after {
    border-top-color: darkgrey;
  }
}

.popover.popover-bottom, .popover.bs-tether-element-attached-top {
  background-color: white !important;
  margin-top: 10px;
  border-radius: 0.4rem;
  &:after {
    border-bottom-color: darkgrey;
  }
}  

.info-form-modal.modal-lg{
  width:600px !important;
}

.box-export-modal{
  display: inline-flex;
  outline: none;
}

.loader-subtable{
  display: inline-block;
  vertical-align: text-top;
}

loader-subtable .ball-clip-rotate > div{
  color : #ffffff !important;
}

.subtable-ui{
  border-right: 1px solid #ddd !important;
  text-align: center;
  vertical-align: center;
}



.br-right{
  border-right : 1px solid #ddd !important;
}

.red-bg-error{
  background-color: indianred !important;
  color: white;
}

.btn-green-sm{
  background-color: #46B147;
  font-size: 10px !important;
  border-radius: 2px !important;
  padding: 2px 2px;
}

.editable-none{
  width: 57px;
  height: 21px;
}

.subtable-meal-hover:hover{
  background-color: transparent !important;
}