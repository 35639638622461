.toggle-container {
    display: inline-block;
    border-radius: 5px;
    background-color: #eceeef; /* Background color of the inactive area */
    padding: 2px;
    border: 1px solid #B1BABF;
    margin-bottom: -0.8rem;
  }

  .toggle-container-disable {
    display: inline-block;
    border-radius: 5px;
    background-color: #eceeef; /* Background color of the inactive area */
    padding: 2px;
    border: 1px solid #B1BABF;
    margin-bottom: -0.8rem;
    cursor: not-allowed;
  }
  
  .toggle-button-disable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: not-allowed;
  }
  .toggle-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggle-option {
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  .toggle-option-disable {
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    cursor: not-allowed;
  }
  
  #false-option.active {
    background-color: #09819A; /* The color you want for the active "False" option */
    color: white; /* Text color for the active option */
  }
  
  #true-option.active {
    background-color: #09819A; /* The color you want for the active "True" option */
    color: white; /* Text color for the active option */
  }
  