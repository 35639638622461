/*
 * Variables
 */

$quiet-color: #ccc;
$loud-color: #000;

$link-color: #006CD1;
$link-hover-color: saturate(#006CD1, 20%);
$link-visited-color: purple;

$base-font-family: 'MuseoSans', Roboto !important;
$base-font-size: 14px;
$base-font-weight: 400;

$fixed-font-family:'MuseoSans', Roboto !important;
$fixed-font-size: 15px;
$fixed-line-height: 1.2;

$heading-font-weight: 400;

@font-face{
   font-family: 'text-security-disc';
   src: url('./fonts/text-security-disc-compat.ttf') format('truetype');
}

/*
 * Base styles
 */
@font-face {
   font-family: MuseoSans;
   font-weight: 100;
   src: url('./fonts/MuseoSans_100.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 300;
   src: url('./fonts/MuseoSans_300.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 500;
   src: url('./fonts/MuseoSans_500.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 700;
   src: url('./fonts/MuseoSans_700.otf');
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');

html {
  height: 100%;
}
body {
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: 1.4;
  font-family: $base-font-family;
  margin-right: 0 !important;
  color: #001E2E;
}


/*
 * Typography
 */

.link {
  color: $link-color;
  text-decoration: none;
  
  &:visited {
    color: $link-visited-color;
  }
  
  &:hover, &:visited:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

.fixed { font-family: $fixed-font-family; font-size: $fixed-font-size; line-height: $fixed-line-height; }

.quiet { font-color: $quiet-color; }
.loud  { font-color: $loud-color; }

.italic { font-style: italic; }
.bold   { font-weight: 700; @extend .loud; }

h1, h2, h3, h4, h5, h6 {
  font-weight: $heading-font-weight;
  color: $text-dark;
}
h1, h2 { line-height: 1.1; }
h3, h4 { line-height: 1.3; }
h1 { font-size: 400%; letter-spacing: -2px; }
h2 { font-size: 250%; letter-spacing: -1px; }
h3 { font-size: 200%; }
h4 { font-size: 180%; }
h5 { font-size: 130%; }
h6 { font-size: 100%; }


.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: $heading-font-weight;
  color: $text-dark;
}
.h1, .h2 { line-height: 1.1; }
.h3, .h4 { line-height: 1.3; }
.h1 { font-size: 400%; letter-spacing: -2px; }
.h2 { font-size: 250%; letter-spacing: -1px; }
.h3 { font-size: 200%; }
.h4 { font-size: 180%; }
.h5 { font-size: 130%; }
.h6 { font-size: 100%; }

.block-margins { margin: 1em 0; }

.unordered-list { list-style-type: disc; }
.ordered-list { list-style: decimal; }

.typography {
  a { @extend .link; }

  i, em { @extend .italic; }
  b, strong { @extend .bold; }
  
  h1 { @extend .h1; margin: 1em 0 0.5em; }
  h2 { @extend .h2; margin: 1em 0 0.5em; }
  h3 { @extend .h3; margin: 1em 0 0.5em; }
  h4 { @extend .h4; margin: 1em 0 0.5em; }
  h5 { @extend .h5; margin: 1em 0 0.5em; }
  h6 { @extend .h6; margin: 1em 0 0.5em; }
  
  p, ul, ol, pre { @extend .block-margins; }
  
  ul { @extend .unordered-list; }
  ol { @extend .ordered-list; }
  
  pre, code { @extend .fixed; }
}


/*
 * Modifiers
 */

.text-left   { text-align: left   !important; }
.text-center { text-align: center !important; }
.text-right  { text-align: right  !important; }


/*
 * Object definitions
 */

.header-block {
  font-size: 110%;
  
  &.typography h1 { font-size: 450%; margin-top: 0 !important; }
}

.author-block {
  border-top: 3px double $quiet-color;
  margin-top: 4em;
}

.demo-class {
  background-color: red; 
}

a:focus, a:hover {
  color: #096579;
}