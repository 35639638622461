.hover-form-employee-count-tooltip .tooltip-inner{
    background: #E2ECF1;
    position:relative;
    max-height: 150px;
    overflow-y:auto;
    width: 94%;
    left: 9px;
  }
  
  .hover-form-employee-count-tooltip .tooltip-inner::-webkit-scrollbar {
    display: none;
  }
  
  .hover-form-employee-count-tooltip .arrow{
    left: -10px;
    margin-top: -2px;
    border-right-color: #E2ECF1 !important;
    border-right-width: 0;
    content: "";
    border-width: 11px;
    display: block;
    width: 0;
    position: absolute;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }