 
  .modal-dialog-css{
      width: 100% !important;
      min-width: 60vw !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0;
    }
    
  .modal-css{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left:auto !important;
      z-index: 1050;
      display: none;
      overflow: hidden;
      outline: 0;
  }
.modal-content-css{
    height: auto;
    min-height: 100%;
    margin-left: 10px;
    border-radius: 0 !important;
  }

  