
// Forms

$input-padding-x:                .75rem;
$input-padding-y:                .5rem;
$input-line-height:              1.25;

$input-bg:                       $white;
$input-bg-disabled:              $gray-lighter;

$input-color:                    $gray;
$input-border-color:             #B1BABF;
$input-btn-border-width:         $border-width; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075);

$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;

$input-bg-focus:                 $input-bg;
$input-border-focus:             lighten($brand-primary, 25%);
$input-box-shadow-focus:         $input-box-shadow, rgba($input-border-focus, .6);
$input-color-focus:              $input-color;

$input-color-placeholder:        $gray-light;

$input-padding-x-sm:             .5rem;
$input-padding-y-sm:             .25rem;

$input-padding-x-lg:             1.5rem;
$input-padding-y-lg:             .75rem;

$input-height:                   (($font-size-base * $input-line-height) + ($input-padding-y * 2));
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s;

$form-text-margin-top:     .25rem;
$form-feedback-margin-top: 0;

$form-check-margin-bottom:  .5rem;
$form-check-input-gutter:   1.25rem;
$form-check-input-margin-y: .25rem;
$form-check-input-margin-x: .25rem;

$form-check-inline-margin-x: .75rem;

$form-group-margin-bottom: 0;

$input-group-addon-bg:           $gray-lightest;
$input-group-addon-border-color: $input-border-color;

.col-form-label {
  font-size: 14px;
  padding-top: 2px !important;
}
.invalid-feedback {
  font-size: 12px;
  min-height: 18px;
}

.form-control {
  font-size: 13px !important;
  line-height: 1.2 !important;
}
form {
  margin-top: 10px;
}
.form-check {
  display: inline-block !important;
}
.form-control[readonly] {
  background-color: $white !important;
}
.form-control:disabled {
   background-color: #DDDDDD !important;
}
.search .input-group-addon {
  padding: 0 !important;
}
@media all and (-ms-high-contrast:none) {
  input[type=text]::-ms-clear {
    display: none;
  }
}
.board-form {
  height: 100vh;
}

.form-section-signup{
  overflow-x: auto;
  padding: 0 !important;
  min-height: 100vh !important;
}

.info-section-signup{
  background: #f3f3f3 !important;
}

.form-control:focus {
  border-color: #162d6e !important;
}

.form-control-error {
  border-color: #d9534f !important;
}

.form-control-label {
  display: inline-block !important;
  margin-bottom: 0rem !important;
}

div[color="danger"] {
  .invalid-feedback {
    color: #d9534f !important;
  }
  .invalid-label {
    color: #d9534f !important;
  }
  .col-form-label {
    color: #d9534f !important;
  }
  .form-check-label {
    color: #d9534f !important;
  }
  .custom-control {
    color: #d9534f !important;
  }
}


div[color="danger"] {
  .form-control {
    border-color: #d9534f !important;
  }
  .input-group-addon {
    color: #d9534f !important;
    border-color: #d9534f !important;
    background-color: #fdf7f7 !important;
  }
  .invalid-danger {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") !important;
  }
}

