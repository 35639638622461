.wizard {
  margin: 20px auto;
  background: #efefef;
}
.wizard .nav-tabs {
  border: none;
}
.wizard > div.wizard-inner {
  position: relative;
}
.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 39%;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
  margin-left: 286px;
}
.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}
span.round-tab {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  left: 0;
  text-align: center;
  font-size: 25px;
}
span.round-tab i{
  color:#555555;
}
.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid #5bc0de;
    
}
.wizard li.active span.round-tab i{
  color: #5bc0de;
}
.wizard .nav-tabs > li {
  width: 25%;
  margin-left: 216px;
  z-index:999;
}
.wizard .nav-tabs > li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}
@media (max-width: 1200px) {
  .connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 41.7%;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
    margin-left: 286px;
  } 
}


