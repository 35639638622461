.form-container {
    width: 100%;
  }
  
  .form-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .file-upload-section {
    display: flex;
    width: 60%;
    gap: 10px;
    align-items: center;
  }
  
  .upload-button {
    width: 15%;
    height: fit-content;
    margin-top: 1.7rem;
  }
  
  .status-box-danger {
    display: flex;
    height: 5rem;
    width: 10rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    border-bottom: 4px solid red;
}

.status-box-success {
  display: flex;
  height: 5rem;
    width: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  border-bottom: 4px solid #46B147;
}


  .status-text{
    font-size: 18px;
    font-weight: bolder;
    margin-right: 28px;
}
  
  
  .refresh-card-style {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    cursor: pointer;
  }
  
  .subhead-font {
    margin: 0.5rem 0;
    font-size: 1rem;
    margin-right: 2rem;
  }
  
  .checkbox-toggle-section {
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -1rem;
    gap: 1rem;
  }
  
  .checkbox-input {
    margin-right: 6px;
  }
  