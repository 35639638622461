$dropdown-margin-top: 4px;
$dropdown-item-padding-x: 12px;
$dropdown-link-active-bg: $brand-primary;
$dropdown-link-active-color: $gray-lightest;
$dropdown-link-hover-bg: #9CDAD7;

// .dropdown {
  // z-index: 999;
// }
.dropdown-menu {
  border-radius: 3px;
  box-shadow: 0 4px 8px -2px rgba(9,30,66,.25), 0 0 1px rgba(9,30,66,.31);
  border: none !important;
  font-size: 14px !important;
  min-width: 0rem !important;
  transform: none !important;
}
.dropdown-header {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px !important;
  background: #9CDAD7;
}
.dropdown-divider {
  margin:0 0 4px 0 !important;
}
.dropdown-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  cursor: pointer;
  font-weight: 300 !important;
  &:disabled {
    cursor: not-allowed !important;
  }
}