/* Table styles */
.table-wrapper {
  width: 97%;
}

.t-head {
  background: #001e2e;
  color: white;
  position: sticky;
}

.t-body {
  overflow-y: auto;
  display: grid;
  max-height: 350px;

  width: 100%;
}

.table-timecard-main {
  margin-top: 1rem;
}

.Header-column th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #001e2e;
  color: white;
  position: sticky;
}

/* FolderTreeTable styles */
.folder-row {
  width: 100%;
}

.folder-cell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder-cell-padding {
  padding-left: 10px;
}

.folder-cell-level {
  padding-left: 20px;
}

.folder-toggle {
  cursor: pointer;
  margin-right: 5px;
}

.folder-link {
  flex: 1;
}

.folder-name {
  flex: 1;
}

.download-icon {
  cursor: pointer;
  margin-left: 1rem;
}

.tbody-grid {
  display: grid;
  max-height: 350px;
  width: 100%;
}

.testButton {
  height: 1.8rem;
  width: 5rem;
  font-size: 0.8rem;
  font-weight: bolder;
  color: white;
  background-color: #09819a;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: #efefef !important;
    cursor: pointer;
    color: #001e2e;
  }
}
