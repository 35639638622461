$custom-file-height:            34px;;
$custom-file-width:             100%;
$custom-file-focus-box-shadow:  0 0 0 .075rem $white, 0 0 0 .2rem $brand-primary;

$custom-file-padding-x:         .5rem;
$custom-file-padding-y:         1rem;
$custom-file-line-height:       1.2;
$custom-file-color:             $gray;
$custom-file-bg:                $white;
$custom-file-border-width:      $border-width;
$custom-file-border-color:      $input-border-color;
$custom-file-border-radius:     $border-radius;
$custom-file-box-shadow:        inset 0 .2rem .4rem rgba($black,.05);
$custom-file-button-color:      $custom-file-color;
$custom-file-button-bg:         $gray-lighter;

.custom-file {
  width: 100%;
}
.custom-file input {
  cursor: pointer;
}
.custom-file-control:lang(en)::after {
    content: " " !important;
}