//Popover
.notification-popover {
  width:321px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  max-width: 321px !important;
}

.notification-popover:before{
  left: 221px;
  margin-top: -15px;
  border-right-color: white !important;
  border-left-width: 0 !important;
  transform: rotate(90deg);
}

.notification-heading{
  width: 100%;
  margin: auto;
  height: auto;
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #242424;
  opacity: 1;
  word-break: break-all;
  font-family: "MuseoSans" !important;
}

.notification-text{
  text-align: left;
  font-size: 11px;
  letter-spacing: 0;
  color: #242424;
  opacity: 1;
  font-weight: 500;
  font-family: "MuseoSans" !important;
}

.notification-delete{
  width: 10px;
  height: 13px;
  opacity: 1;
}

.notification-item{
  width: 100%;
  opacity: 1;
  padding: 12px !important;
  border-right: none !important;
  border-left: none !important;
}

.notification-time{
  height: 9px;
  text-align: left;
  font-size: 10px;
  text-align: left;
  letter-spacing: 0;
  color: #242424;
  opacity: 1;
  font-weight: 500;
  font-family: "MuseoSans"  !important;
}

.notification-head{
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  color: #242424;
  opacity: 1;
}

.notification-scroll::-webkit-scrollbar {
  width: 3px !important;
  height: 8px;
}

.notification-list{
  float: right;
  font-weight: 500;
}

.popover-border{
  border:1px solid #09819A;
  opacity:1;
}

.notification-height{
  overflow-y: auto; 
  max-height: 350px;
}

.no-notification{
  height: 350px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "MuseoSans" !important;
}