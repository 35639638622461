// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem;
$btn-padding-y:                  .5rem;
$btn-line-height:                1.25;
$btn-font-weight:                $font-weight-normal;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125);

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $brand-ascent;
$btn-secondary-border:           $brand-ascent;

$btn-info-color:                 $white;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-success-color:              $white;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-warning-color:              $white;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               $white;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$btn-link-disabled-color:        $gray-light;

$btn-padding-x-sm:               .5rem;
$btn-padding-y-sm:               .25rem;

$btn-padding-x-lg:               1.5rem;
$btn-padding-y-lg:               .75rem;

$btn-block-spacing-y:            .5rem;
$btn-toolbar-margin:             .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

$btn-transition:                 all .2s ease-in-out;

.btn {
  cursor: pointer;
}
.btn-secondary:hover {
	color: #fff !important;
    background-color: #00b147 !important;
    border-color: #00b147 !important;
}