
.rangeslider {
  margin: 14px 0 5px;
}
.rangeslider-horizontal {
  height: 8px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #bc353d;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 20px;
  height: 20px;
}
.rangeslider-horizontal .rangeslider__handle:after {
  width: 10px;
  height: 10px;
  top: 4px;
  left: 4px;
}
.rangeslider-horizontal .rangeslider__handle:focus {
  outline: none;
}


.slick-arrow {
  background: transparent !important;
}
.slick-prev{
  left: 3px !important;
  z-index: 9 !important;
}
.slick-next{
  right: 3px !important;
  z-index: 9 !important;
}
.slick-prev:before, .slick-next:before {
  font-size: 55px !important;
  color: #2b1570 !important;
  font-family: inherit !important;
  font-weight: 100 !important;
}
.slick-prev:before {
  content: '\2039' !important;
}
.slick-next:before {
  content: '\203a' !important;
}
.slick-prev, .slick-next {
  top: 40% !important ;
  color: #2b1570 !important;
}
.slick-track {
  display: flex !important;
  justify-content: center !important;
}
