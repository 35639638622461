.child-table td {
  background: none !important;
  border:none !important;
}
.button-icon {
  height: 20px;
  width: 20px;
}
.search-allign{
  padding: 0.35rem .75rem; 
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.search-Icon{
  margin-bottom: -1px !important;
}

.verify-audit{
  color: #46B147;
}

.valid-button {
  height: 30px;
  min-width: auto;
  background-color: #1D2870 !important;
  border-color: #1D2870 !important;
  font-size: 12px !important;
  padding: 6px 6px  !important;
}

.unvalid-button {
  background-color: #a9a9a9 !important;
  color: #fff !important;
  border-color: #a9a9a9 !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 12px !important;
  padding: 6px 6px  !important;
  line-height: 1.5em;

  &:hover {
    background-color: #a9a9a9 !important;
    border-color: #a9a9a9 !important;
  };
}

.img-wrap {
  position: relative;
  height: 80px;
  width: 80px;
  margin-right: auto;
  margin-left: auto;
}

.img-wrap img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  -webkit-transform: translate(-50%,-50%);
  -webkit-backface-visibility: hidden;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  object-fit: scale-down;
  max-width: 100%;
}

.mar-top {
margin-top: 32px;
}

.valid-button {
  height: 30px;
  min-width: auto;
  background-color: #1D2870 !important;
  border-color: #1D2870 !important;
  font-size: 12px !important;
  padding: 6px 6px  !important;
}

.unvalid-button {
  background-color: #a9a9a9 !important;
  color: #fff !important;
  border-color: #a9a9a9 !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 12px !important;
  padding: 6px 6px  !important;
  line-height: 1.5em;

  &:hover {
    background-color: #a9a9a9 !important;
    border-color: #a9a9a9 !important;
  };

}

.custom-white{
  padding: 52px !important ;
}

.main-heading {
  font-size: 26px;
}

.highlight-text {
  color : #162d6e;
  font-size: 11px !important;
  padding-left: 8.1% !important;
  padding-bottom: 4px !important;
}
.highlight-text:focus {
  outline: none;
}

.highlight-text:hover{
  background : white;
}
.form-button {
  font-size: 15px !important;
  padding: 0.3rem 1.5rem 0.3rem !important;
}
.roleButtonLoaderPos{
  position: absolute;
  top: 7px;
  margin-left: 3px;
}
.saveButtonLoaderPos{
  margin-left: 3px;
  position: absolute;
  top: 23px;
}
.username-div {
  background-color: lightblue;
  font-size: 17px;
  max-height: 38px;
  width: fit-content
}
.hidden-field{
  z-index: -1;
  position: absolute;
  width: 0px;
  height: 0px;
}
.subheader-ellipses{
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-header {
  width: 100%;
  display: flex;
  background-color: white;
  border: none;
  border-top: 2px solid #162d6e;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  translate: 0.2s all;

  &--closed {
    margin-bottom: 1rem;
  }

  &__label {
    &--quoted {
      display: flex;
      flex: 1 1 10rem;
      cursor: pointer;
      padding-top: 1rem;
      margin-left: 5px;
      color: black;
      font-weight: 500;
      img {
        transition: all 0.2s;
      }
      &--up {
        margin: 0.4rem;
        margin-top: 0.25rem;
        transform: rotateZ(180deg);
      }
      &--down {
        margin: 0.4rem;
        margin-top: 0.25rem;
        transform: rotateZ(0deg);
      }
    }
    &--total {
      color: black;
      margin-top: 1rem;
    }
  }

  &__search-bar {
    height: 2rem;
  }

  &__btn {
    margin: 0.7rem;
    button {
      border-radius: 3px !important;
      border: none;
      color: #fff;
      background-color: #46b147;
      font-size: 14px !important;
      padding: 0.4rem;
      width: 6rem;
      cursor: pointer;
    }
    &--refresh {
      margin: 0 10px;
      margin-top: 1rem;
      cursor: pointer;
    }
  }
}
.attribute-form {
  width: 28% !important;
  color: #242424;

  &__body {
    padding: 0.5vw 1.5vw !important;
    overflow: auto;
    height: 70vh;
  }

  &__header {
    padding: 25px 20px 5px !important;
    font-size: 12px !important;
    border-bottom: 2px solid #09819A !important;
    button {
      font-size: 2.1rem;
      font-weight: 300;
    }
    h4 {
      color: #242424;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      padding: 0.8rem 1rem;
      border-bottom: 1px solid #dddddd;
    }
  }

  &__action {
    width: 100%;
    font-size: 1rem;
    padding: 0.6rem 0;
    margin: auto;
    display: flex;
    justify-content: space-between;

    &--label {
      width: 80%;
    }

    &--switch {

    }
  }
}
.department-checkbox-custom{
  padding-top: 2rem !important;
}
.search-allign{
  padding: 0.35rem .75rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.search-Icon{
  margin-bottom: -1px !important;
}
.input-icon-clear{
  position: absolute;
  left: 95%;
  margin-top: 8px !important;
  margin-right: -12px;
  z-index: 3;
  padding-top: 1px !important;
  padding-left: 4px !important;
  cursor: pointer;
  float: right;
}
.checkBox-custom-size{
  margin-left: 85%;
  width: 17px;
  height: 18px;
  position: absolute;
}
.uoList-custom {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
  span {
    text-overflow: ellipsis;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.search-custom-width{
  width: 150% !important;
}
.modal-assign-buttom-custom-width{
  width: 75% !important;
}
.checkbox-label-custom{
  vertical-align: middle !important;
}
.link-disabled{
  .a{
    color: gray !important;
  }
}
.record-custom{
  font-size: 18px;
  vertical-align: middle;
  justify-content: center;
  height: 50vh;
}
.modal-custom-header{
  border-bottom: 2px solid #09819A !important;
}

.modal-custom-header h5{
  color: #242424;
  font-size: 180%;
}

.border-round{
  border-radius:5px !important;
}

.horizontal-line{
  border-top: 2px solid rgba(0,0,0,0.25) !important;
}

.label-part{
  letter-spacing: 0.2px;
  line-height:1.4;
  font-size: 12px;
  color: rgba(0,0,0,0.6);
}

.manage-tags{
  border-bottom: 1px solid #09819A;
  padding: 10px 15px;
  
}

.tags-list{
  height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 2px solid #dddddd;
  margin-bottom: 20px;
  position:relative;
  
}

.taglist{
  padding: 0 14px 10px;
  margin-top: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-tags-form{
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;

}

.custom-position{
  margin-left: 1.2rem;
  margin-right: 2rem;
  padding-top: 0.3rem;
}

.checkbox-tags{
  left: 9%;
  margin-top: 6px;
}

.checkbox-tags-list{
  left: 6.5%;
  margin-top: 0.2rem !important;
}

.deletetag{
  text-decoration:underline;
}

.allbranch-width{
  max-width: 150px;
}

.reset-biometric-button {
  height: 34px;
  min-width: auto;
  font-size: 14px !important;
  padding: 6px 6px  !important;
  float: right;
  text-align: center !important;
}

.cancel-btn {
  background-color: grey !important;
  border-color: grey !important;
  color: white !important;
}

.yes-btn{
  min-width: 85.53px !important;
}

.save-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}




.forms-Header-col{
  background-color: white !important;
  color: #707070 !important;
}

.profile-container{
  position: relative;
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: whitesmoke;
  }
  .edit-btn-container{
    border: 2px solid #a9a9a9;
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  input{
    display: none;
  }
}

.action-icon{
  path{
    fill: #09819A;
  }
}

.profile-edit{
  height: 10px;
  padding: 1px;
  path{
    fill: #09819A;
  }
}