// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .5rem;

$table-bg:                      transparent;

$table-inverse-bg:              $gray-dark;
$table-inverse-color:           $body-bg;

$table-bg-accent:               rgba($black,.05);
$table-bg-hover:                #9CDAD7;
$table-bg-active:               $table-bg-hover;

$table-head-bg:                 $gray-lighter;
$table-head-color:              $gray;

$table-border-width:            $border-width;
$table-border-color:            $gray-lighter;

.table {
  margin-top: 20px;
  margin-bottom: 0px !important;
  background: white;
}

.table thead th {
  text-transform: uppercase;
  font-size: 12px;
  border-top: none;
  vertical-align: baseline !important;
  font-weight:500 !important;
}
.table tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color:#001E2E;
}
.table tbody tr td .button2link {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Customer Table Styling */

.table-customer {
  font-size: 14px !important;
  color: #242424 !important;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  border-radius: 0.2rem;
}

// Styles to apply border-radius on the top
table.table-customer thead th:first-child
{
  border-radius:0.2rem 0 0 0;
}

table.table-customer thead th:last-child
{
  border-radius:0 0.2rem 0 0;
}

.table-customer tr:hover {
  background-color: #9CDAD7 !important;
}

.table-tracking.table tbody tr td:nth-child(2) {
  max-width: 105px;
  min-width: 105px;
}
.table-tracking.table thead th:nth-child(2) {
  min-width: 105px;
}

.table-tracking.table tbody tr td:nth-child(3) {
  max-width: 110px;
  min-width: 110px;
}
.table-tracking.table thead th:nth-child(3) {
  min-width: 110px;
}

.table-emp.table tbody tr td:nth-child(2) {
  max-width: 90px;
  min-width: 90px;
}
.table-emp.table thead th:nth-child(2) {
  min-width: 90px;
}

.table-customer.table thead th {
  color: #fff !important;
  text-align: center;
  background-color: #001E2E !important;
  height: 39px;
  vertical-align: middle !important;
}

.table-customer.table tbody tr td {
  padding-top: 0.5rem !important;
}

.table-customer.table tbody tr td:nth-child(1) {
  border: none;
  text-align: center;
  max-width: 50px;
  padding-top: 0.8rem !important;
}
.table-customer.table tbody tr td:nth-child(2) {
  border: none;
  text-align: center;
  max-width: 100px;
  padding-top: 0.8rem !important;
}
.table-customer.table tbody tr td:nth-child(3) {
  border: none;
  text-align: center;
  max-width: 100px;
  padding-top: 0.8rem !important;
}
.table-customer.table tbody tr td:nth-child(4) {
  border: none;
  text-align: center;
  max-width: 100px;
  padding-top: 0.8rem !important;
}
.table-customer.table tbody tr td:nth-child(5) {
  border: none;
  text-align: center;
  max-width: 100px;
  padding-top: 0.8rem !important;
}
.table-customer.table tbody tr td:nth-child(6) {
  border: none;
  text-align: center;
  min-width: 50px;
}

.table-customer.table tbody tr td:nth-child(7) {
  border: none;
  text-align: center;
  min-width: 50px;
  border-right: 1px solid #F2F2F2 !important;
}

/* CSS for Task tables */
.table-task {
  font-size: 12px !important;
  color: #242424 !important;
}

table.table-task {
  border-radius: 0.2rem !important;
}

// Styles to apply border-radius on the top
table.table-task thead th:first-child
{
  border-radius:0.2rem 0 0 0;
}

table.table-task thead th:last-child
{
  border-radius:0 0.2rem 0 0;
}

table.table-task tbody tr td:last-child {
  border: none !important;
  padding-top: 0.8rem;
}

.table-task tr:hover not(thead){
  background-color: #9CDAD7 !important;
}

table.table-task tbody tr:last-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

.table-task.table tbody tr td {
  max-width: 144px;
  min-width: 144px;
  font-size: 14px !important;
}
.table-task.table thead th {
  min-width: 144px;
  height: 39px !important;
  vertical-align: middle !important;
}
.table-task.table tbody tr td{
  min-width: 28px;
  border: none !important;
}
.table-task.table tbody tr td:nth-child(1) {
  min-width: 28px;
  padding-left: 1rem;
}
.table-task.table thead th:nth-child(1) {
  text-align: center;
  min-width: 50px;
}

.table-task.table thead th:nth-child(2) {
  min-width: 80px;
  text-align: center;
}

.table-task.table thead th:nth-child(3) {
  min-width: 100px;
  text-align: center;
}

.table-task.table thead th:nth-child(4) {
  min-width: 50px;
  text-align: center;
}

.table-task.table thead th:nth-child(5) {
  min-width: 100px;
  text-align: center;
}

.table-task.table thead th:nth-child(6) {
  text-align: center;
}

.table-task.table thead th:nth-child(7) {
  min-width: 50px;
  text-align: center;
}

.table-task.table thead th:nth-child(8) {
  text-align: center;
}
.table-task.table thead th:nth-child(9) {
  text-align: center;
}
.table-task.table thead th:nth-child(10) {
  text-align: center;
}

.table-task.table tbody tr td:nth-child(1) {
  border: none;
  min-width: 50px;
  text-align: center;
  padding-top: 0.7rem;
}

.table-task.table tbody tr td:nth-child(2) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(3) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(4) {
  border: none;
  min-width: 50px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(5) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(6) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(7) {
  border: none;
  min-width: 50px;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(8) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(9) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

.table-task.table tbody tr td:nth-child(10) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task {
  font-size: 12px !important;
  color: #242424 !important;
}

table.job-table-task {
  border-radius: 0.2rem !important;
}

// Styles to apply border-radius on the top
table.job-table-task thead th:first-child
{
  border-radius:0.2rem 0 0 0;
}

table.job-table-task thead th:last-child
{
  border-radius:0 0.2rem 0 0;
}

table.job-table-task tbody tr td:last-child {
  border: none !important;
  padding-top: 0.8rem;
}

.job-table-task tr:hover not(thead){
  background-color: #9CDAD7 !important;
}

table.job-table-task tbody tr:last-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

.job-table-task.table tbody tr td {
  max-width: 144px;
  min-width: 144px;
  font-size: 14px !important;
}
.job-table-task.table thead th {
  min-width: 144px;
  height: 39px !important;
  vertical-align: middle !important;
}
.job-table-task.table tbody tr td{
  min-width: 28px;
  border: none !important;
}
.job-table-task.table tbody tr td:nth-child(1) {
  min-width: 28px;
  padding-left: 1rem;
}
.job-table-task.table thead th:nth-child(1) {
  text-align: center;
  min-width: 50px;
}
.job-table-task.table thead th:nth-child(2) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(3) {
  min-width: 80px;
  text-align: center;
}

.job-table-task.table thead th:nth-child(4) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(5) {
  min-width: 100px;
  text-align: center;
}

.job-table-task.table thead th:nth-child(6) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(7) {
  min-width: 100px;
  text-align: center;
}

.job-table-task.table thead th:nth-child(8) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(9) {
  min-width: 100px;
  text-align: center;
}

.job-table-task.table thead th:nth-child(10) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(11) {
  text-align: center;
}

.job-table-task.table thead th:nth-child(12) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(13) {
  min-width: 50px;
  text-align: center;
}

.job-table-task.table thead th:nth-child(14) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(15) {
  text-align: center;
}

.job-table-task.table thead th:nth-child(16) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(17) {
  text-align: center;
}

.job-table-task.table thead th:nth-child(18) {
  min-width: 70px;
}

.job-table-task.table thead th:nth-child(19) {
  text-align: center;
}

.job-table-task.table tbody tr td:nth-child(1) {
  border: none;
  min-width: 50px;
  text-align: center;
  padding-top: 0.7rem;
}

.job-table-task.table tbody tr td:nth-child(3) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(5) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(7) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(9) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(11) {
  border: none;
  min-width: 80px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(13) {
  border: none;
  min-width: 50px;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(15) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(17) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

.job-table-task.table tbody tr td:nth-child(19) {
  border: none !important;
  text-align: center;
  padding-top: 0.8rem;
}

/* Styling for the geofences table begin */

table.geo_fence_table {
  border-radius: 0.2rem;
}

.geo_fence_table {
  font-size: 14px !important;
  color: #001E2E !important;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

.geo_fence_table thead {
  color: #fff;
  height: 39px !important;
  background-color: #001E2E;
}

table.geo_fence_table thead th:first-child
{
  border-radius:0.2rem 0 0 0;
}

table.geo_fence_table thead th:last-child
{
  border-radius:0 0.2rem 0 0;
}

.geo_fence_table tr:hover not(thead){
  background-color: #9CDAD7 !important;
}

.geo_fence_table.table thead th:nth-child(1) {
  text-align: center;
  min-width: 100px;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table thead th:nth-child(2) {
  text-align: center;
  min-width: 100px;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table thead th:nth-child(3) {
  text-align: center;
  min-width: 100px;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table thead th:nth-child(4) {
  text-align: center;
  min-width: 100px;
}

.geo_fence_table.table thead th:nth-child(5) {
  text-align: center;
  min-width: 100px;
  width: 11%;
}

.geo_fence_table.table thead th:nth-child(6) {
  text-align: center;
  min-width: 100px;
}

.geo_fence_table.table thead th:nth-child(7) {
  text-align: center;
  min-width: 100px;
}

.geo_fence_table.table thead th:nth-child(8) {
  text-align: center;
  min-width: 100px;
}

.geo_fence_table.table tbody tr td:nth-child(1) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(2) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(3) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(4) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(5) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(6) {
  border: none;
  min-width: 100px;
  text-align: center;
  padding-top: 0.8rem !important;
}

.geo_fence_table.table tbody tr td:nth-child(7) {
  border: none;
  min-width: 50px;
  text-align: center;
}

/* Styles for Job Reports Table */

.table-reports {
  font-size: 14px !important;
  color: #001E2E !important;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
}

// Styles to apply border-radius on the top
table.table-reports thead th:first-child
{
  border-radius:0.2rem 0 0 0;
}

table.table-reports thead th:last-child
{
  border-radius:0 0.2rem 0 0;
}

.table-reports thead th {
  text-align: left;
  height: 39px;
  vertical-align: middle !important;
  background-color: #001E2E !important;
  color:#fff;
}

.table-reports tr:hover {
  background-color: #9CDAD7 !important;
}

.table-reports tbody tr td {
  text-align: center;
}

.table-reports tbody tr td {
  border: none;
}

.table-reports tbody tr td:last-child {
  border: none !important;
}

.table-reports.table tbody tr td {
  max-width: 120px;
  min-width: 120px;
}
.table-reports.table thead th {
  min-width: 120px;
}
.table-reports.table tbody tr td:nth-child(1) {
  min-width: 156px;
  max-width: 190px;
}
.table-reports.table thead th:nth-child(1) {
  min-width: 156px;
  max-width: 190px;
}
.table-reports.table tbody tr td:nth-child(7) {
  min-width: 156px;
  max-width: 190px;
}
.table-reports.table thead th:nth-child(7) {
  min-width: 156px;
  max-width: 190px;
}
.table-reports.table tbody tr td:nth-child(8) {
  min-width: 156px;
  max-width: 190px;
}
.table-reports.table thead th:nth-child(8) {
  min-width: 156px;
  max-width: 160px;
}

.table-subtable.table tbody tr td {
  min-width: 100px;
  max-width: 102px;
}
.table-subtable.table thead th {
  min-width: 60px;
}
.table-subtable.table thead th:nth-child(1) {
  max-width: 40px !important;
}
.table-subtable.table tbody tr td:nth-child(1) {
  min-width: 60px;
}

.table-subtable.table tbody tr td:nth-child(2) {
  min-width: 145px;
}
.table-subtable.table tbody tr td:nth-child(3) {
  min-width: 85px;
}
/*.table-subtable.table tbody tr td:nth-child(8) {
  min-width: 28px;
}
.table-subtable.table thead th:nth-child(8) {
  min-width: 28px;
}*/
.table-subtable.table thead th:nth-child(1) {
  min-width: 28px;
}
.table-subtable.table thead th:nth-child(2) {
  min-width: 30px;
}
/*.table-dt.table tbody tr td {
  max-width: 82px !important;
}*/
/*.table-dt.table thead th {
  min-width: 50px;
}*/
.dt-first-colom {
 width: 100px !important;
 display: inline-block;
 text-overflow: ellipsis;
 overflow: hidden;
}

.table-daily-timecard.table thead {
  background: #001E2E !important;
  color: #fff !important;
}

.table-inline-view.table thead{
  background: #001E2E !important;
  color: #fff !important;
}

.table-inline-view.table th {
  border-left: 1px solid #eceeef !important;
}

.table-daily-timecard.table thead th:nth-child(1) {
  height: 37px !important;
  width:260px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(1) {
  height: 37px !important;
}
.table-daily-timecard.table thead th:nth-child(2) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(2) {
  width:63px !important;
}

.table-daily-timecard.table thead th:nth-child(3) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(3) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(4) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(4) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(5) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(5) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(6) {
  width:63px !important;
  padding-left: 4px;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(6) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(7) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(7) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(8) {
  width:63px !important;
  padding-left: 4px;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(8) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(9) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(9) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(10) {
  width:63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(10) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(11) {
  width: 63px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(11) {
  width:63px !important;
}
.table-daily-timecard.table thead th:nth-child(12) {
  width:60px !important;
  padding-left: 4px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(12) {
  width:60px !important;
}
.table-daily-timecard.table thead th:nth-child(13) {
  width:69px !important;
  padding-left: 4px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(13) {
  width:69px !important;
}
.table-daily-timecard.table thead th:nth-child(14) {
  width:69px !important;
}
.table-daily-timecard.table tbody tr td .table tbody tr td:nth-child(14) {
  width:69px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(1) {
  height: 37px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(2) {
  width:68px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(3) {
  width:82px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(4) {
  width:82px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(5) {
  width:68px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(6) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(7) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(8) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(9) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(10) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(11) {
  width:63px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(12) {
  width:50px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(13) {
  width:69px !important;
}
.table-daily-timecard.table tr:last-child td:nth-child(14) {
  width:69px !important;
}

.weeklyTotal td:nth-child(1) {
  height: 37px !important;
}
.weeklyTotal td:nth-child(2) {
  width:68px !important;
}
.weeklyTotal td:nth-child(3) {
  width:82px !important;
}
.weeklyTotal td:nth-child(4) {
  width:82px !important;
}
.weeklyTotal td:nth-child(5) {
  width:68px !important;
}

.table-daily-timecard.table tr:last-child td:nth-child(13) {
  width:69px !important;
}

.weeklyTotal td:nth-child(6) {
  width:63px !important;
}
.weeklyTotal td:nth-child(7) {
  width:63px !important;
}
.weeklyTotal td:nth-child(8) {
  width:63px !important;
}
.weeklyTotal td:nth-child(9) {
  width:63px !important;
}
.weeklyTotal td:nth-child(10) {
  width:63px !important;
}
.weeklyTotal td:nth-child(11) {
  width:63px !important;
}
.weeklyTotal td:nth-child(12) {
  width:60px !important;
}
.weeklyTotal td:nth-child(13) {
  width:69px !important;
}
.weeklyTotal td:nth-child(14) {
  width:69px !important;
}
.table-reports.table tbody tr td:nth-child(11) {
  min-width: 116px;
  max-width: 180px;
}
.table-reports.table thead th:nth-child(11) {
  min-width: 116px;
  max-width: 180px;
}

.table-meal-violation-data-detail thead th {
  min-width: 170px;
}

.table-meal-violation-data-detail tbody tr td {
  min-width: 170px;
}

.table-meal-flag-detail thead th {
  min-width: 170px;
  max-width: 250px !important;
}

.table-meal-flag-detail tbody tr td{
  min-width: 170px;
  max-width: 250px !important;
}

.table-timecard.table thead th:nth-child(1) {
  min-width:25px;
  max-width:68px;
}

.timecardTH th {
  font-weight: lighter !important;
}

.timecardTD td:not(.firstname_col_td){
  color:#001E2E;
  font-style:"Roboto";
  font-weight: 400;
}

.action_col {
  min-width:25px;
  width: 25px;
}

.table-timecard.table tbody tr td:nth-child(1) {
  min-width:25px;
  max-width:68px;
}

.firstname_col{
  min-width:114px;
  width: 114px;
}
.firstname_col_td{
  min-width: 115px;
  width: 115px;
  max-width: 115px;
}

.table-timecard.table thead th:nth-child(2) {
  min-width:111px;
  max-width:111px;
}
.lastname_col{
  min-width:111px;
  max-width:111px;
  width: 111px;
}

.lastname_col_td{
  min-width:112px;
  width: 112px;
  max-width: 112px;
}

.table-timecard.table tbody tr td .table tbody tr td:nth-child(1) {
  min-width:111px;
  max-width:111px;
}
.table-timecard.table thead th:nth-child(3) {
  min-width:109px;
  max-width:109px;
}

.table-timecard.table thead th:nth-child(3) {
  min-width:109px;
  max-width:109px;
}

.employeeid_col{
  min-width:117px;
  width: 117px;
}

.employeeid_col_td{
  min-width:119px;
  width: 119px;
  max-width: 119px;
}

.table-timecard.table tbody tr td .table tbody tr td:nth-child(2) {
  min-width:109px;
  max-width:109px;
}
.table-timecard.table thead th:nth-child(4) {
  min-width:119px;
  max-width:119px;
}
.table-timecard.table tbody tr td .table tbody tr td:nth-child(3) {
  min-width:119px;
  max-width:119px;
}
.table-timecard.table thead th:nth-child(5) {
  min-width:140px;
  max-width:140px;
}

.employeetitle_col{
  min-width:140px;
  max-width:140px;
  width: 140px;
}

.employeetitle_col_td{
  min-width:142px;
  width: 142px;
  max-width: 142px;
}

.table-timecard.table tbody tr td .table tbody tr td:nth-child(4) {
  min-width:140px;
  max-width:140px;
}
.table-timecard.table thead th:nth-child(6) {
  min-width:66px;
  max-width:66px;
}

.punchin_col{
  min-width:78px;
  max-width:78px;
  width: 78px;
}

.punchin_col_td{
  min-width:78px;
  max-width:78px;
  width: 78px;
}

.mealperiod_col{
  min-width:94px;
  max-width:94px;
  width: 94px;
}

.mealperiod_col_td{
  min-width:94px;
  max-width:94px;
  width: 94px !important;
}

.table-timecard.table tbody tr td .table tbody tr td:nth-child(5) {
  min-width:66px;
  max-width:66px;
}
.table-timecard.table thead th:nth-child(7) {
  max-width:97px;
  min-width:97px;
}

.totaltime_col{
  max-width:97px;
  min-width:97px;
  width: 97px;
}

.totaltime_col_td{
  max-width:99px;
  min-width:99px;
  width: 99px;
}


.table-timecard.table tbody tr td .table tbody tr td:nth-child(6) {
  min-width:97px;
  max-width:97px;
}
.table-timecard.table thead th:nth-child(8) {
  min-width:65px;
  max-width:65px;
}
.table-timecard.table tbody tr td .table tbody tr td:nth-child(7) {
  min-width:65px;
  max-width:65px;
}

.regulartime_col{
  min-width:83px;
  width: 83px;
}

.regulartime_col_td{
  min-width:84px;
  width: 84px;
}

.table-timecard.table thead th:nth-child(9) {
  min-width:72px;
  max-width:72px;
}
.table-timecard.table tbody tr td .table tbody tr td:nth-child(8) {
  min-width:72px;
  max-width:72px;
}
.overtime_col{
  min-width:72px;
  max-width:72px;
  width: 72px;
}
.overtime_col_td{
  min-width:75px;
  max-width:75px;
  width: 75px;
}

.table-timecard.table thead th:nth-child(10) {
  min-width:72px;
  max-width:72px;
}
.table-timecard.table tbody tr td .table tbody tr td:nth-child(9) {
  min-width:72px;
  max-width:72px;
}

.doubleovertime_col{
  min-width:72px;
  max-width:72px;
  width: 72px;
}

.doubleovertime_col_td{
  min-width:75px;
  max-width:75px;
  width: 75px;
}

.table-timecard.table thead th:nth-child(11) {
  min-width:77px;
  max-width:77px;
}
.table-timecard.table tbody tr td .table tbody tr td:nth-child(10) {
  min-width:77px;
  max-width:77px;
}
.table-timecard.table thead th:nth-child(12) {
  min-width:75px;
  max-width:75px;
}
.mileage_col{
  min-width:75px;
  max-width:75px;
  width: 75px;
}

.mileage_col_td{
  min-width:75px;
  max-width:75px;
  width: 75px;
}
.approve_col{
  min-width:75px;
  max-width:75px;
  width: 75px;
}

.table-timecard.table tbody tr td .table tbody tr td:nth-child(11) {
  min-width:75px;
  max-width:75px;
  text-overflow: ellipsis;
}

.table-activity.table {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.table-activity.table tbody tr td {
  max-width: 130px;
  min-width: 130px;
}
.table-activity.table thead th {
  min-width: 130px;
}

.table-activity.table tbody tr td:nth-child(1) {
  max-width: 63px;
  min-width: 63px;
}
.table-activity.table thead th:nth-child(1) {
  min-width: 63px;
}

.table-activity.table tbody tr td:nth-child(4) {
   max-width: 184px;
  min-width: 184px;
}
.table-activity.table thead th:nth-child(4) {
  max-width: 184px;
  min-width: 184px;
}
.table-activity.table tbody tr td:nth-child(5) {
   max-width: 184px;
  min-width: 184px;
}
.table-activity.table thead th:nth-child(5) {
  max-width: 184px;
  min-width: 184px;
}

.table-job.table tbody tr td {
  min-width: 156px;
}
.table-job.table thead th {
  min-width: 156px;
}
.table-job-time.table thead th {
  min-width: 165px;
}
.table-job-time.table tbody tr td {
  max-width: 165px;
  min-width: 165px;
}
.table-job-time.table thead th:last-child {
  min-width: 200px;
}
.table-job-time.table tbody tr td:last-child {
  max-width: 200px;
  min-width: 200px;
}
.table-job.table tbody tr td:nth-child(1) {
  min-width: 20px;
}
.table-job.table thead th:nth-child(1) {
  min-width: 28px;
}
.table-employee-daily-timecard-report thead th:nth-child(4){
  min-width: 148px;
}
.table-employee-daily-timecard-report thead th:nth-child(5){
  min-width: 178px;
}
.table-employee-daily-timecard-report thead th:nth-child(8){
  min-width: 138px;
}
.table-employee-daily-timecard-report thead th:nth-child(9){
  min-width: 190px;
}
.table-employee-daily-timecard-report thead th:nth-child(10){
  min-width: 211px;
}
.table-employee-daily-timecard-report thead th:nth-child(11){
  min-width: 183px;
}
.table-employee-daily-timecard-report thead th:nth-child(13){
  min-width: 180px;
}
.table-employee-daily-timecard-report thead th:nth-child(14){
  min-width: 180px;
}

.table-overtime-report thead th:nth-child(4){
  min-width: 180px;
}
.table-overtime-report thead th:nth-child(6){
  min-width: 180px;
}
.table-overtime-report thead th:nth-child(7){
  min-width: 180px;
}
.table-overtime-report thead th:nth-child(8){
  min-width: 200px;
}

.table-job-time-allocation-report thead th:nth-child(6){
  min-width: 180px;
}
.table-job-time-allocation-report thead th:nth-child(7){
  min-width: 163px;
}
.table-job-time-allocation-report thead th:nth-child(8){
  min-width: 178px;
}
.table-job-time-allocation-report thead th:nth-child(9){
  min-width: 183px;
}
.table-job-time-allocation-report thead th:nth-child(10){
  min-width: 181px;
}

.table-knowledge-base thead th:nth-child(2){
  min-width: 70px;
}

.table-knowledge-base td:nth-child(1){
  vertical-align: middle;
}

.table-knowledge-base td:nth-child(2){
  vertical-align: middle;
}

.table-knowledge-base td:nth-child(3){
  vertical-align: middle;
}

.table-knowledge-base td:nth-child(4){
  vertical-align: middle;
}

.table-knowledge-base td:nth-child(5){
  vertical-align: middle;
}

.table-knowledge-base td:nth-child(6){
  vertical-align: middle;
}

.table-kiosk-audit thead th:nth-child(3){
  min-width: 90px !important;
  max-width: 90px !important;
}

.table-kiosk-audit td:nth-child(1){
  vertical-align: middle;
}

.table-kiosk-audit td:nth-child(2){
  vertical-align: middle;
}

.table-kiosk-audit td:nth-child(3){
  vertical-align: middle;
  min-width: 90px !important;
  max-width: 90px !important;
}

.table-kiosk-audit td:nth-child(4){
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: normal !important;
  vertical-align: middle !important;
}

.table-kiosk-audit td:nth-child(5){
  vertical-align: middle;
}

.table-kiosk-audit td:nth-child(6){
  vertical-align: middle;
}

.table-kiosk-audit td:nth-child(5){
  vertical-align: middle;
}

.table-kiosk-audit td:nth-child(6){
  vertical-align: middle;
}

.table-kiosk-audit-employees thead th:nth-child(5){
  min-width: 150px !important;
  max-width: 150px !important;
}

.table-kiosk-audit-employees thead th:nth-child(3){
  min-width: 120px !important;
  max-width: 120px !important;
}

.table-kiosk-audit-employees thead th:nth-child(7){
  min-width: 50px !important;
  max-width: 50px !important;
}

.table-kiosk-audit-employees thead th:nth-child(4){
  min-width: 250px !important;
  max-width: 250px !important;
}

.table-kiosk-audit-employees td:nth-child(1){
  vertical-align: middle;
}

.table-kiosk-audit-employees td:nth-child(2){
  vertical-align: middle;
}

.table-kiosk-audit-employees td:nth-child(3){
  vertical-align: middle;
  min-width: 120px !important;
  max-width: 120px !important;
}

.table-kiosk-audit-employees td:nth-child(4){
  vertical-align: middle;
}

.table-kiosk-audit-employees td:nth-child(5){
  vertical-align: middle;
  min-width: 150px !important;
  max-width: 150px !important;
}

.table-kiosk-audit-employees td:nth-child(6){
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: normal !important;
  vertical-align: middle !important;
}



.table-job-time-allocation.table thead th:first-child,
.table-job-time-allocation.table tbody tr td:first-child {
  min-width: 60px;
}
.table-job-time-allocation.table thead th,
.table-job-time-allocation.table tbody tr td {
  min-width: 160px;
}
.table-job-time-allocation.table thead th:nth-child(7) {
  min-width: 120px;
}

@media screen and (min-width: 1920px) {
  .table-timecard.table {
    table-layout: fixed;
    width: 100%;
  }
  .table-responsive {
    margin-top: 20px;
    max-height: 70vh;
  }
  .table-daily-timecard {
    table-layout: fixed;
    width: auto;
  }
  .table-inline-view {
    width: auto;
  }
}

@media screen and (min-width: 2560px) {
  .table-responsive {
    margin-top: 20px;
    max-height: 80vh;
  }
}

@media all and (-ms-high-contrast:none) {
  .table{
    margin-bottom: 1rem !important;
  }
}

.qbd-metc-table tbody tr:last-child .autocomplete-drop-menu {
  top: initial !important;
  bottom: 54px !important;
}

.qbd-metc-table thead th:nth-child(1){
  width: 100px;
}

.qbd-metc-table thead th:nth-child(2){
  width: 230px;
}

.qbd-metc-table thead th:nth-child(3){
  width: 280px;
}

.qbd-metc-table thead th:nth-child(4){
  width: 280px;
}

.daily-timecard-table {
  margin-top: 10px !important;
}

.daily-timecard-table.table thead th:nth-child(3),
.daily-timecard-table.table tbody tr td:nth-child(3){
  min-width: 110px !important;
  max-width: 110px !important;
}

.daily-timecard-table.table thead th:nth-child(4),
.daily-timecard-table.table tbody tr td:nth-child(4){
  min-width: 135px !important;
  max-width: 135px !important;
}

.daily-timecard-table.table thead th:nth-child(9),
.daily-timecard-table.table tbody tr td:nth-child(9){
  min-width: 130px !important;
  max-width: 130px !important;
}

.customer-view-table tbody tr td {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: none;
}

.shift-icon{
  display: none;
}

.dt-row {
  &:hover{
    & .shift-icon {
      display: inline-block !important;
    }
  };
}

.table-daily-timecard.table  td {
  height: 37px !important;
}

.table-inline-view.table td {
  height: 37px !important;
}

.vendor-table.table thead th:nth-child(4){
  min-width: 150px;
}

.vendor-table.table tbody tr td:nth-child(4){
  min-width: 150px;
}

.vendor-table.table thead th:nth-child(5){
  min-width: 168px;
}

.vendor-table.table tbody tr td:nth-child(5){
  min-width: 168px;
}

.vendor-table.table thead th:nth-child(2){
  min-width: 168px;
}

.vendor-table.table tbody tr td:nth-child(2){
  min-width: 168px;
}

.vendor-table.table thead th:nth-child(6){
  min-width: 80px;
}

.vendor-table.table tbody tr td:nth-child(6){
  min-width: 80px;
}

.vendor-table.table thead th:nth-child(9){
  min-width: 150px;
}

.vendor-table.table tbody tr td:nth-child(9){
  min-width: 150px;
}

.table-invoice.table tbody tr td {
  max-width: 140px;
  min-width: 140px;
}
.table-invoice.table thead th {
  min-width: 140px;
}
.table-invoice.table tbody tr td:nth-child(1) {
  min-width: 60px;
  text-align: center;
}
.table-invoice.table thead th:nth-child(1) {
  min-width: 130px;
  text-align: center;
}

.table-invoice.table tbody tr td:nth-child(2) {
  min-width: 40px;
  text-align: center;
}
.table-invoice.table thead th:nth-child(2) {
  min-width: 130px;
  text-align: center;
}

.table-invoice.table tbody tr td:nth-child(3) {
  min-width: 40px;
  text-align: center;
}
.table-invoice.table thead th:nth-child(3) {
  min-width: 130px;
  text-align: center;
}


.table-invoice.table tbody tr td:nth-child(4) {
  min-width: 40px;
  text-align: center;
}

.table-invoice.table thead th:nth-child(4) {
  min-width: 130px;
  text-align: center;
}


.table-invoice.table tbody tr td:nth-child(5) {
  min-width: 40px;
  text-align: center;
}
.table-invoice.table thead th:nth-child(5) {
  min-width: 130px;
  text-align: center;
}

.table-tracking.table tbody tr td {
  max-width: 140px;
  min-width: 140px;
}
.table-tracking.table thead th {
  min-width: 140px;
}
.table-tracking.table tbody tr td:nth-child(1) {
  min-width: 28px;
}
.table-tracking.table thead th:nth-child(1) {
  min-width: 28px;
}

.table-emp.table tbody tr td {
  max-width: 175px;
  min-width: 175px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.table-emp.table thead th {
  min-width: 175px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.table-emp.table tbody tr td:nth-child(1) {
  min-width: 37px;
  padding: 12px;
}
.table-emp.table thead th:nth-child(1) {
  min-width: 37px;
  padding: 12px;
}
.table-emp.table tbody tr td:nth-child(2) {
  max-width: 90px;
  min-width: 90px;
  padding: 12px;
}
.table-emp.table thead th:nth-child(2) {
  min-width: 90px;
  padding: 12px;
}

.survey-table.table tbody tr td:nth-child(4) {
  min-width: 190px !important;
}

.survey-table.table thead th:nth-child(4) {
  min-width: 190px !important;
}

.survey-table.table tbody tr td:nth-child(5) {
  min-width: 130px !important;
}

.survey-table.table thead th:nth-child(5) {
  min-width: 130px !important;
}

.survey-table.table tbody tr td:nth-child(6) {
  min-width: 130px !important;
}

.survey-table.table thead th:nth-child(6) {
  min-width: 130px !important;
}

.table-blank{
  background: transparent !important;
}

.table-timecard-main.table tbody tr td {
  min-width: 140px;
}
.table-timecard-main.table thead th {
  min-width: 140px;
}
.table-timecard-main.table tbody tr td:nth-child(1) {
  min-width: 28px;
}
.table-timecard-main.table thead th:nth-child(1) {
  min-width: 28px;
}
.table-timecard-main.table tbody tr td:nth-child(2) {
  min-width: 130px;
}
.table-timecard-main.table thead th:nth-child(2) {
  min-width: 130px;
}

.date_col{
  width: 325px;
  min-width: 325px;
  max-width: 325px;
  border-right : 1px solid #ddd !important;
}

.date_col_head{
  width: 308px;
  min-width: 308px;
  max-width: 308px;
}

.punch_in_col_head{
  width: 95px;
  min-width: 95px;
  max-width: 95px;
}

.punch_in_col{
  width: 96px;
  min-width: 96px;
}

.weekly_col{
  width: 326px;
  min-width: 326px;
  max-width: 326px;
}


.weekly_col_head{
  width: 73px;
  min-width: 73px;
  max-width: 73px;
}

.weekly_punchout{
  width: 65px;
  min-width: 65px;
  max-width: 65px;
}

.weekly_meal{
  width: 76px;
  min-width: 76px;
  max-width: 76px;
}
.timeCard-table tr:hover td {
    background-color: #9CDAD7 !important;
  }

.dailytimecard .table-daily-timecard{
  background-color: #001E2E !important;
}
.table-col-size{
  width:350px;
}
.table-text-alignment td{
  text-align: center;
}
.table-heading-alignment thead th{
  text-align: center !important;
}
.not-aligned{
  text-align: left !important;
}
.text-aligned{
  text-align: center !important;
}

.timecard-td-padding{
  padding-left: 6px !important;
}

.timecard-blank-col{
  min-width: 60px !important;
}

.timecard-blank-col-td{
  min-width: 61px !important;
}

.drag-icon{
  visibility: hidden;
}

.timeCard-table tr:hover .drag-icon{
  visibility: visible !important;
}

/*form table design*/

.table-form.table tbody tr td:nth-child(1) {
  max-width: 30px;
  min-width: 30px;
}
.table-form.table tbody tr td:nth-child(2) {
  max-width: 120px;
  min-width: 80px;
}
.table-form.table tbody tr td:nth-child(3) {
  max-width: 50px;
  min-width: 50px;
}
.table-form.table tbody tr td:nth-child(4) {
  max-width: 120px;
  min-width: 120px;
}
.table-form.table tbody tr td:nth-child(5) {
  max-width: 150px;
  min-width: 150px;
}
.table-form.table tbody tr td:nth-child(6) {
  max-width: 70px;
  min-width: 70px;
}

.table-form.table tbody tr td:nth-child(7) {
  max-width: 120px;
  min-width: 120px;
}
.table-form.table tbody tr td:nth-child(8) {
  max-width: 90px;
  min-width: 90px;
}


.table-form.table thead th:nth-child(2) {
  min-width: 90px;
}
.table-form.table thead th:nth-child(1) {
  min-width: 50px;
}
.table-form.table thead th:nth-child(9) {
  min-width: 20px;
}
.table-form.table tbody tr td {
  max-width: 120px;
  min-width: 120px;
}
.table-form.table thead th {
  min-width: 120px;
}
.table-form.table thead th:nth-child(1) {
  min-width: 37px;
  padding: 12px;
}

.table-form.table thead th:nth-child(2) {
  min-width: 80px;
  padding: 12px;
}
.table-form.table thead th:nth-child(3) {
  min-width: 50px;
  padding: 12px;
}
.table-form.table thead th:nth-child(4) {
  min-width: 120px;
  padding: 12px;
}
.table-form.table thead th:nth-child(4) {
  min-width: 120px;
  padding: 12px;
}
.table-form.table thead th:nth-child(5){
  padding: 12px;
  min-width: 60px;
}
.table-form.table thead th:nth-child(6){
  padding: 12px;
  min-width: 70px;
}
.table-form.table thead th:nth-child(7){
  max-width: 120px;
  min-width: 120px;
}
.table-form.table thead th:nth-child(8){
  padding: 12px;
  min-width: 100px;
}